<nsa-modal *transloco="let transloco">
  <ng-container modal-body>
    <div class="ps-2 pe-2 pb-4">
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 p-2">
          <nsa-input
            nsaId="nsf-profile-name-id"
            nsaName="nsf-profile-name-name"
            [cardView]="true"
            label="HEADER.PROFILE.MODAL.NAME"
            [ngModel]="data.user.name"
            [disabled]="true">
          </nsa-input>
        </div>

        <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 p-2">
          <nsa-input
            nsaId="nsf-profile-lastname-id"
            nsaName="nsf-profile-lastname-name"
            [cardView]="true"
            label="HEADER.PROFILE.MODAL.LASTNAME"
            [ngModel]="data.user.lastname"
            [disabled]="true">
          </nsa-input>
        </div>

        <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 p-2">
          <nsa-input
            nsaId="nsf-profile-email-id"
            nsaName="nsf-profile-email-name"
            [cardView]="true"
            label="HEADER.PROFILE.MODAL.EMAIL"
            [ngModel]="data.user.email"
            [disabled]="true">
          </nsa-input>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 p-2">
          <nsa-input
            nsaId="nsf-profile-role-id"
            nsaName="nsf-profile-role-name"
            [cardView]="true"
            label="HEADER.PROFILE.MODAL.ROLE"
            [ngModel]="data.user.role.name"
            [disabled]="true">
          </nsa-input>
        </div>
      </div>
    </div>
  </ng-container>
</nsa-modal>
